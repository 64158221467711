import { Moment } from 'moment';
import { GetUserFunction } from '../hooks/useAuth';
import { newMoment } from '../utilities/moment';
import {
  ActivityLog,
  ApiActivityLog,
  isEditedPendingSyncApiActivityLogEntry,
  isNewPendingSyncApiActivityLogEntry,
} from './activityLogs.types';
import { getActivityLog as getActivityLogFromApi } from './api';

export const apiActivityLogEntryToActivityLogEntry = (
  apiModel: ApiActivityLog
): ActivityLog => {
  const eventTime: Moment | undefined = !!apiModel.eventTime
    ? newMoment(apiModel.eventTime)
    : undefined;

  if (isEditedPendingSyncApiActivityLogEntry(apiModel)) {
    const { id, ...rest } = apiModel;
    return {
      ...rest,
      apiId: id,
      tag: 'editedPendingSync',
      eventTime,
    };
  } else if (isNewPendingSyncApiActivityLogEntry(apiModel)) {
    return {
      ...apiModel,
      tag: 'newPendingSync',
      eventTime,
    };
  }

  const { id, ...rest } = apiModel;
  return {
    ...rest,
    apiId: id,
    eventTime,
  };
};

export const getActivityLog = (
  getUser: GetUserFunction
): Promise<ActivityLog[]> => {
  return getActivityLogFromApi(getUser).then((logs: ApiActivityLog[]) =>
    Promise.resolve(logs.map(apiActivityLogEntryToActivityLogEntry))
  );
};
