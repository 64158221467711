import { Moment } from 'moment';
import { PeopleCount } from '../services/tally';
import { FormType } from './lists';

/** Local Models */

export interface NewPendingSyncActivityLogEntry
  extends Omit<ActivityLogEntry, 'apiId'> {
  tag: 'newPendingSync';
}

export interface EditedPendingSyncActivityLogEntry extends ActivityLogEntry {
  tag: 'editedPendingSync';
}

export interface ActivityLogEntry {
  apiId: number;
  friendlyId: string;
  type: FormType;
  dropOffLocation?: string;
  pickUpLocation?: string;
  conditions?: string[];
  eventTime?: Moment;
  people?: PeopleCount[];
  interactionType?: string;
  eventType?: string;
  actionType?: string;
  actions?: string[];
}

export type ActivityLog =
  | NewPendingSyncActivityLogEntry
  | EditedPendingSyncActivityLogEntry
  | ActivityLogEntry;

export const isNewPendingSyncActivityLogEntry = (
  value: ActivityLog
): value is NewPendingSyncActivityLogEntry => {
  return 'tag' in value && value['tag'] === 'newPendingSync';
};

export const isEditedPendingSyncActivityLogEntry = (
  value: ActivityLog
): value is NewPendingSyncActivityLogEntry => {
  return 'tag' in value && value['tag'] === 'editedPendingSync';
};

/** API Models */

export interface ApiActivityLogEntry {
  id: number;
  friendlyId: string;
  type: FormType;
  dropOffLocation?: string;
  pickUpLocation?: string;
  conditions?: string[];
  eventTime?: string;
  people?: PeopleCount[];
  eventType?: string;
  actionType?: string;
  interactionType?: string;
  actions?: string[];
}

export interface NewPendingSyncApiActivityLogEntry
  extends Omit<ApiActivityLogEntry, 'id'> {
  tag: 'newPendingSync';
}

export const isNewPendingSyncApiActivityLogEntry = (
  value: ApiActivityLog
): value is NewPendingSyncApiActivityLogEntry => {
  return (
    !('id' in value) && 'tag' in value && value['tag'] === 'newPendingSync'
  );
};

export interface EditedPendingSyncApiActivityLogEntry
  extends ApiActivityLogEntry {
  tag: 'editedPendingSync';
}

export const isEditedPendingSyncApiActivityLogEntry = (
  value: ApiActivityLog
): value is EditedPendingSyncApiActivityLogEntry => {
  return (
    'id' in value && 'tag' in value && value['tag'] === 'editedPendingSync'
  );
};

export type PendingSyncApiActivityLogEntry =
  | NewPendingSyncApiActivityLogEntry
  | EditedPendingSyncApiActivityLogEntry;

export type ApiActivityLog =
  | ApiActivityLogEntry
  | PendingSyncApiActivityLogEntry;

export interface ApiGetActivityLogResponse {
  from: string;
  to: string;
  logEntryCount: number;
  logEntries: ApiActivityLog[];
}
