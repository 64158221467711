import { useEffect, useState } from 'react';
import { getActivityLog } from '../services/activityLogs';
import { ActivityLog } from '../services/activityLogs.types';
import { GetUserFunction } from './useAuth';

export interface ActivityLogState {
  loaded: boolean;
  activityLogs: ActivityLog[];
}

export const useActivityLog = ({
  getUser,
}: {
  getUser: GetUserFunction;
}): ActivityLogState => {
  const [loaded, setLoaded] = useState(false);
  const [activityLogs, setActivityLogs] = useState<ActivityLog[]>([]);

  useEffect(() => {
    getActivityLog(getUser)
      .then((logEntries): void => {
        setActivityLogs(logEntries);
      })
      .finally(() => {
        setLoaded(true);
      });
  }, []);

  return {
    loaded,
    activityLogs,
  };
};
