import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import ActivityLogCard from '../components/activityLogCard';
import { useAuthContext } from '../components/authContext';
import CenterHeader from '../components/centerHeader';
import Layout from '../components/layout';
import { StartEditForm, useEditForm } from '../forms/form';
import { useActivityLog } from '../hooks/useActivityLog';
import useAuthRouteProtection from '../hooks/useAuthRouteProtection';
import useIsOnline from '../hooks/useIsOnline';
import { ActivityLog } from '../services/activityLogs.types';
import { BaseProps } from '../typings/types';

const useStyles = makeStyles({
  activityLogContainer: {
    marginTop: '1.8rem',
  },
});

interface PureActivityLogProps {
  loaded: boolean;
  activityLogs: ActivityLog[];
  editActivity: StartEditForm;
  isOnline: boolean;
}

export const PureActivityLog: React.FC<PureActivityLogProps> = ({
  loaded,
  activityLogs,
  editActivity,
  isOnline,
}) => {
  if (activityLogs)
    activityLogs.sort((first, second) => {
      if (!first.eventTime && !second.eventTime) return 0;
      if (!first.eventTime) return 1;
      if (!second.eventTime) return -1;
      if (second.eventTime > first.eventTime) return 1;
      if (first.eventTime > second.eventTime) return -1;
      return 0;
    });
  const classes = useStyles();
  return (
    <Layout title="Activity log" wrapper={false}>
      <Typography>Displaying last 24 hours</Typography>
      <Grid
        alignContent="flex-start"
        justify="space-between"
        direction="row"
        container
        className={classes.activityLogContainer}
      >
        {!loaded ? (
          <CenterHeader>Loading...</CenterHeader>
        ) : activityLogs.length === 0 ? (
          <CenterHeader>No entries</CenterHeader>
        ) : (
          activityLogs.map((a: ActivityLog, index: number) => {
            return (
              <ActivityLogCard
                key={index.toString()}
                activityLog={a}
                editActivity={editActivity}
                isOnline={isOnline}
              />
            );
          })
        )}
      </Grid>
    </Layout>
  );
};

export const ActivityLogPage: React.FC<BaseProps> = ({ location }) => {
  useAuthRouteProtection(location.pathname);

  const { isOnline } = useIsOnline();
  const { getUser } = useAuthContext();
  const activityLogState = useActivityLog({ getUser });

  const startEdit = useEditForm();
  return (
    <PureActivityLog
      {...activityLogState}
      editActivity={startEdit}
      isOnline={isOnline}
    />
  );
};

export default ActivityLogPage;
