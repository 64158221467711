import { Chip, Grid, IconButton, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { StartEditForm } from '../forms/form';
import { ChildrenFamilySupport, Collaboration } from '../icons';
import Chat from '../icons/chat';
import Community from '../icons/community';
import Medical from '../icons/medical';
import PencilIcon from '../icons/pencil';
import Referral from '../icons/referral';
import Support from '../icons/support';
import TransportIcon from '../icons/transportIcon';
import {
  ActivityLog,
  ActivityLogEntry,
  isEditedPendingSyncActivityLogEntry,
  isNewPendingSyncActivityLogEntry,
} from '../services/activityLogs.types';
import { FORM_TYPE_NAME, FormType } from '../services/lists';
import { PeopleCount } from '../services/tally';
import { CYAN_LIGHT, STEEL_BLUE } from '../theme';

const useStyles = makeStyles((theme: Theme) => ({
  activityIdentifierRow: {
    gap: '0.25rem',
  },
  activityLogCardContainer: {
    borderTop: `1px solid ${theme.palette.primary.contrastText}`,
    paddingTop: '1.1rem',
    paddingBottom: '1rem',
    fontSize: '1rem',
    lineHeight: '1.1rem',
  },
  alignRight: {
    display: 'block',
    textAlign: 'right',
    marginTop: '0.25rem',
  },
  iconButton: {
    backgroundColor: STEEL_BLUE,
    color: CYAN_LIGHT,
    borderRadius: '0.1875rem',
    padding: '0.4375rem',
    fontSize: '1.3rem',
  },
}));

const getIcon = (logType: string): React.ReactElement => {
  const props = { color: 'secondary', fontSize: 'large' };
  switch (logType) {
    case FormType.Prevention:
      return <Chat {...props} />;
    case FormType.Community:
      return <Community {...props} />;
    case FormType.Transport:
      return <TransportIcon {...props} />;
    case FormType.Referral:
      return <Referral {...props} />;
    case FormType.PlannedMedical:
      return <Medical {...props} />;
    case FormType.Yes:
      return <Support {...props} />;
    case FormType.Collaboration:
      return <Collaboration {...props} />;
    case FormType.ChildrenFamilySupport:
      return <ChildrenFamilySupport {...props} />;
    default:
      return <></>;
  }
};

const ActivityDescription: React.FC<{ activity: ActivityLog }> = ({
  activity,
}) => {
  switch (activity.type) {
    case FormType.Prevention: {
      const conditions = activity.conditions
        ? activity.conditions.join(', ')
        : '';
      return (
        <>
          <Typography variant="body2">
            Type: {FORM_TYPE_NAME.Prevention}
          </Typography>
          <Typography variant="body2">Condition: {conditions} </Typography>
        </>
      );
    }
    case FormType.Community: {
      return (
        <>
          <Typography variant="body2">
            Type: {FORM_TYPE_NAME.Community}
          </Typography>
          <Typography variant="body2">
            Activity: {activity.eventType}
          </Typography>
        </>
      );
    }
    case FormType.Referral: {
      return (
        <>
          <Typography variant="body2">
            Type: {FORM_TYPE_NAME.Referral}
          </Typography>
        </>
      );
    }
    case FormType.Transport: {
      return (
        <>
          <Typography variant="body2">
            Type: {FORM_TYPE_NAME.Transport}
          </Typography>
          <Typography variant="body2">
            Pick up: {activity.pickUpLocation}
          </Typography>
          <Typography variant="body2">
            Drop off: {activity.dropOffLocation}
          </Typography>
        </>
      );
    }
    case FormType.PlannedMedical: {
      return (
        <>
          <Typography variant="body2">
            Type: {FORM_TYPE_NAME.PlannedMedical}
          </Typography>
          <Typography variant="body2">
            Pick up: {activity.pickUpLocation}
          </Typography>
          <Typography variant="body2">
            Drop off: {activity.dropOffLocation}
          </Typography>
        </>
      );
    }
    case FormType.Yes: {
      return (
        <>
          <Typography variant="body2">Type: {FORM_TYPE_NAME.Yes}</Typography>
        </>
      );
    }
    case FormType.Collaboration: {
      return (
        <>
          <Typography variant="body2">
            Type: {FORM_TYPE_NAME.Collaboration}
          </Typography>
          <Typography variant="body2">{activity.eventType}</Typography>
        </>
      );
    }
    case FormType.ChildrenFamilySupport: {
      return (
        <>
          <Typography variant="body2">
            Type: {FORM_TYPE_NAME.ChildrenFamilySupport}
            <Typography variant="body2">{activity.eventType}</Typography>
          </Typography>
        </>
      );
    }
    default:
      return <></>;
  }
};

const PendingSyncChip: React.FC<{}> = () => {
  return (
    <Chip
      color={'secondary'}
      variant={'outlined'}
      label="Pending sync"
      size="small"
    />
  );
};

const getPersonCount = (people?: PeopleCount[]): string => {
  const count = people ? people.reduce((sum, p) => p.count + sum, 0) : 0;
  if (count === 0) {
    return '-';
  } else if (count === 1) {
    return 'Individual';
  } else {
    return `Group of ${count}`;
  }
};

export const ActivityLogCard: React.FC<{
  activityLog: ActivityLog;
  editActivity: StartEditForm;
  isOnline: boolean;
}> = ({ activityLog, editActivity, isOnline }) => {
  const { friendlyId, type: logType, eventTime, people } = activityLog;
  const personCount = getPersonCount(people);
  const icon = getIcon(logType);
  const classes = useStyles();

  const isPendingSync = (): boolean =>
    isNewPendingSyncActivityLogEntry(activityLog) ||
    isEditedPendingSyncActivityLogEntry(activityLog);

  return (
    <Grid
      className={classes.activityLogCardContainer}
      container
      direction="row"
    >
      <Grid item xs={2}>
        {icon}
      </Grid>
      <Grid item xs={6}>
        <Grid container className={classes.activityIdentifierRow}>
          <Typography variant="body1">{friendlyId}</Typography>
          {isPendingSync() && <PendingSyncChip />}
        </Grid>
        <ActivityDescription activity={activityLog} />
      </Grid>
      <Grid item xs={4}>
        <Typography align="right" variant="body1">
          {eventTime ? eventTime.format('HH:mm DD MMM') : ''}
        </Typography>
        <Typography align="right" variant="body2">
          {personCount}
        </Typography>
        <div className={classes.alignRight}>
          <IconButton
            className={classes.iconButton}
            disabled={isPendingSync() || !isOnline}
            onClick={(): Promise<void> =>
              editActivity({
                formType: logType,
                id: (activityLog as ActivityLogEntry).apiId,
              })
            }
            size="small"
          >
            <PencilIcon />
          </IconButton>
        </div>
      </Grid>
    </Grid>
  );
};

export default ActivityLogCard;
